<template>
  <div>
    <GenericBTable
      :items="filteredCompetences"
      :fields="fields"
      :filter="input_search"
      :filter-included-fields="filterOn"
      :filterCustom="filterCustom"
      :show_pagination="true"
      :search_filter="true"
      :selection_mode="true"
      :columns_display="false"
      :pagination="10"
      :per_page="10"
      :display_id="display_id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @emitChangeSearchField="changeInputSearch"
    >
      <template #cell(selected)="row">
        <b-checkbox
          v-model="row.item.selected"
          @change="selectCompetence(row.item.id, $event)"
        ></b-checkbox>
      </template>
      <template #cell(sentence)="row">
        <SentenceContainer :Sentence="row.item"> </SentenceContainer>
      </template>
      <template #cell(type)="row">
        <template v-for="type in [competenceType(row.item.type)]">
          <span
            v-if="type"
            v-b-tooltip.v-secondary="type.description"
            :key="type.id"
            >{{ type.name }}</span
          >
        </template>
      </template>
    </GenericBTable>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "SelectedCompetence",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
  },
  props: {
    type_id: {
      default: null,
    },
    egress_profile_id: {
      default: null,
    },
    cycle_id: {
      default: null,
    },
    display_id: {},
  },
  data() {
    return {
      competences_list: [],
      selected: [],
      filterOn: [],
      input_search: "",
      allows_crud: false,
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      competences: names.COMPETENCES,
      competenceTypes: names.COMPETENCE_TYPES,
      profileCompetences: names.PROFILE_COMPETENCES,
    }),
    filteredCompetences() {
      if (!this.type_id) return this.competences_list;
      return this.competences_list.filter((x) => x.type == this.type_id);
    },
    fields() {
      let list = [
        {
          key: "selected",
          label: "Seleccionar",
          display_column: true,
          thStyle: { width: "1%" },
        },
        {
          key: "sentence",
          label: "Redacción",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
      ];
      if (this.filteredCompetences.filter((x) => x.type != null).length > 0)
        list.push({
          key: "type",
          label: "Tipo",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        });
      return list;
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(this.getNameType(row.type), this.input_search) ||
        this.$filtered(row.full_sentence, this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectCompetence(competence_id) {
      const index = this.selected.findIndex((x) => x == competence_id);
      if (index != -1) this.selected.splice(index, 1);
      else this.selected.push(competence_id);
      this.$emit("slotUpdate", this.selected);
    },
    getNameType(id) {
      if (!id) return "";
      let competence_type = this.competenceTypes.find((x) => x.id == id);
      return competence_type ? competence_type.name : "";
    },
    changeInputSearch(input_search) {
      if (input_search) this.input_search = input_search;
    },
    competenceType(type_id) {
      return this.competenceTypes.find((x) => x.id == type_id);
    },
  },
  mounted() {
    this.competences_list.forEach((x) => {
      x.selected = false;
    });
  },
  created() {
    this.competences_list = [];
    this.competences.forEach((x) => {
      const profile_competence = this.profileCompetences.find(
        (c) =>
          c.competence == x.id && c.egress_profile == this.egress_profile_id
      );
      if (!profile_competence) this.competences_list.push(x);
    });
  },
};
</script>

<style>
</style>